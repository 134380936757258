<template>
  <div class="card card-campaign" @click="goToCampaign">
    <div class="card-body">
      <b-row class="align-items-center">
        <b-col cols="6">
          <h5>DNA Campeão 🎉</h5>
          <div class="progression">
            <b-card-text class="mt-2">
              Progresso anual: <strong>{{ progress }}%</strong>
            </b-card-text>
            <b-progress
                :value="progress"
                max="100"
                variant="success"
                height="20px"
                class="mt-1"
            />
            <span class="badge badge-primary badge-pill mt-2">{{ points }} pontos</span>
          </div>
        </b-col>
        <b-col cols="auto">
          <img
              src="~@/assets/images/dna_campeao.png"
              alt="DNA Campeão"
              height="150"
              width="190"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as kpiTypes from "@/modules/kpi/store/types";
import { isPnRole } from "@/constants/auth";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  data() {
    return {
      progress: 0,
      maxPoints: 0,
      points: 0,
    };
  },
  setup() {
    return { toast: useToast() };
  },
  computed: {
    isPn() {
      return isPnRole(this.user?.user_role_id);
    },
  },
  methods: {
    ...mapActions({
      getDnaAnualProgress: kpiTypes.GET_DNA_ANUAL_PROGRESS,
    }),
    goToCampaign() {
      this.$router.push({ name: "campanhas" });
    },
  },
  mounted() {
    this.getDnaAnualProgress()
        .then((resp) => {
          this.progress = resp.data.progress;
          this.maxPoints = resp.data.max_points;
          this.points = resp.data.points;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os dados da campanha. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
  },
};
</script>

<style scoped lang="scss">
.card-campaign {
  width: 450px;
  cursor: pointer;
}
</style>
